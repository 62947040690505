import classNames from 'classnames'
import { format } from 'date-fns'
import locale from 'date-fns/locale/id'
import Image from 'next/image'
import React from 'react'
import NumberFormat from 'react-number-format'

import { PaymentStatus, Transaction } from '@features/coins/types'

import Paragraph from '@components/typography/paragraph'

import { COINS_OPEN_TRANSACTION_DETAIL } from '@constants/event-tracking'

import trackEvents from '@utils/track-events'

import { useCoinsActions } from '@store/coins/slice'

import TransactionStatusBadge from '../transaction-status-badge'

type Props = {
  transactionType: Transaction
  title: string
  transactionStatus: PaymentStatus
  amount: number
  setOpenDetailModal: React.Dispatch<React.SetStateAction<boolean>>
  transactionId: string
  transactionDate: string
}

const TransactionHistoryCard = ({
  transactionType,
  title,
  transactionStatus,
  amount,
  setOpenDetailModal,
  transactionId,
  transactionDate,
}: Props) => {
  const { setSelectedTransactionId } = useCoinsActions()
  const onOpenDetailModal = () => {
    trackEvents(COINS_OPEN_TRANSACTION_DETAIL)
    setOpenDetailModal(true)
    setSelectedTransactionId(transactionId)
  }

  return (
    <button
      onClick={onOpenDetailModal}
      className={classNames(
        'flex',
        'w-full',
        'items-center',
        'justify-between',
        'rounded-xl',
        'border-[1px]',
        'p-[12px]',
        'gap-x-[12px]',
        'hover:border-primary-blue'
      )}
      type="button"
    >
      <div className="flex gap-x-[16px]">
        <Image
          alt="icon"
          src={
            transactionType === 'Credit'
              ? '/assets/icons/ic_transaction_credit.svg'
              : '/assets/icons/ic_transaction_debit.svg'
          }
          width={30}
          height={30}
          style={{
            maxWidth: '100%',
            height: 'auto',
          }}
        />
        <div className="flex flex-col items-start">
          <Paragraph weight="semibold" className="text-left line-clamp-2">
            {title}
          </Paragraph>
          <p className="text-[12px] font-[400] text-left text-primary-gray">
            {format(new Date(transactionDate), 'dd MMMM yyyy - HH:mm', {
              locale,
            })}
          </p>
        </div>
      </div>

      <div className="flex flex-col items-end">
        <TransactionStatusBadge status={transactionStatus} />
        <div className="mt-2 flex">
          <Image
            alt="icon"
            src="/assets/icons/ic_coin.svg"
            width={20}
            height={20}
            style={{
              maxWidth: '100%',
              height: 'auto',
            }}
          />
          <Paragraph weight="semibold" className="ml-1">
            <NumberFormat
              fixedDecimalScale={false}
              decimalSeparator=","
              thousandSeparator="."
              decimalScale={0}
              value={amount}
              displayType="text"
            />
          </Paragraph>
        </div>
      </div>
    </button>
  )
}

export default TransactionHistoryCard
