import { post } from '@helpers/fetch-wrapper'
import { formatNumbersWithSeparator } from '@helpers/formatter'
import classNames from 'classnames'
import Image from 'next/image'
import { useRouter } from 'next/router'
import React from 'react'
import useSWRImmutable from 'swr/immutable'

import useCoinsBalance from '@features/coins/hooks/useCoinsBalance'

import Button from '@components/button'
import Paragraph from '@components/typography/paragraph'

import {
  COINS_CLICK_TOPUP_BUTTON,
  COINS_OPEN_PRICELIST,
  COINS_OPEN_TRANSACTION_LIST,
} from '@constants/event-tracking'
import { INITIALIZE_URL } from '@constants/url'

import trackEvents from '@utils/track-events'

import { CaretIcon } from '@common/icons'

import { useCoinsActions } from '@store/coins/slice'

import CoinsExpiredModal from '../coins-expired-modal'
import { CLICK_TOPUP_BUTTON_EVENT_ORIGINS } from './constants'

const CoinsBanner = () => {
  const { coinsBalanceData, isValidating: coinsBalanceValidating } =
    useCoinsBalance()
  const { setCoinsPriceListDrawer, setTopupDrawerState } = useCoinsActions()
  const { setTransactionHistoryDrawer, setCoinsExpiredModalState } =
    useCoinsActions()

  const { data: initializeData } = useSWRImmutable<{
    featureToggle: {
      canBoost: boolean
      canUseCoins: boolean
    }
  }>(INITIALIZE_URL, async (url: string) => {
    const response = await post(url)
    return response.data
  })

  const router = useRouter()

  if (!initializeData?.featureToggle.canUseCoins) return null

  return (
    <>
      <div className="relative overflow-hidden bg-[#E4F8FF] px-10 py-4 sticky top-0 z-[49]">
        <div className="absolute right-0 top-0 -z-0">
          <Image
            alt="ic_coin"
            src="/assets/images/img_coin_background.svg"
            height={100}
            width={100}
            style={{
              maxWidth: '100%',
              height: 'auto',
            }}
          />
        </div>
        <div className="relative z-10 flex justify-between">
          {coinsBalanceValidating ||
          coinsBalanceValidating ||
          !coinsBalanceData ? (
            <div>
              <div className="rounded-[6px] h-[15px] w-[150px] animate-pulse bg-neutral-gray-100" />
              <div className="mt-2">
                <span className="rounded-[6px] h-[23px] w-[80px] animate-pulse bg-neutral-gray-100 inline-block mr-2" />
                <span className="rounded-[6px] h-[23px] w-[100px] animate-pulse bg-neutral-gray-100 inline-block" />
              </div>
            </div>
          ) : (
            <div>
              <Paragraph accent="secondary" className="text-xs">
                Atma Coins yang tersedia
              </Paragraph>
              <div className="mt-2 flex items-center">
                <Image
                  alt="icon"
                  src="/assets/icons/ic_coin.svg"
                  width={20}
                  height={20}
                  style={{
                    maxWidth: '100%',
                    height: 'auto',
                  }}
                />
                <p className="font-semibold ml-1 text-xl">
                  {formatNumbersWithSeparator(coinsBalanceData?.balance || 0)}
                </p>

                <Button
                  appearance="outline-primary"
                  type="button"
                  className="ml-2 flex items-center space-x-2 px-2 py-1 hover:bg-white"
                  onClick={() => {
                    const screenOrigin =
                      CLICK_TOPUP_BUTTON_EVENT_ORIGINS[router.pathname]

                    trackEvents(COINS_CLICK_TOPUP_BUTTON, {
                      from: screenOrigin,
                    })
                    setTopupDrawerState({ open: true })
                  }}
                >
                  <Image
                    alt="icon"
                    src="/assets/icons/ic_add_blue.svg"
                    width={20}
                    height={20}
                    style={{
                      maxWidth: '100%',
                      height: 'auto',
                    }}
                  />
                  <Paragraph className="text-primary-blue">Top up</Paragraph>
                </Button>
              </div>
              {coinsBalanceData.expiringBalance > 0 && (
                <button
                  type="button"
                  className={classNames(
                    'mt-[8px]',
                    'text-[12px]',
                    'font-[400]',
                    'bg-neutral-gray-25',
                    'rounded-[6px]',
                    'border',
                    'border-neutral-gray-100',
                    'px-[6px]',
                    'py-[2px]',
                    'flex',
                    'items-center',
                    'text-primary-gray'
                  )}
                  onClick={() => {
                    setCoinsExpiredModalState({ open: true })
                  }}
                >
                  <p>
                    <span className="text-[#E45C3A]">
                      {coinsBalanceData.expiringBalance} koin
                    </span>{' '}
                    akan segera kedaluwarsa
                  </p>
                  <CaretIcon className="rotate-[-90deg]" />
                </button>
              )}
            </div>
          )}

          <div className="flex items-center gap-x-4">
            <button
              type="button"
              className="flex items-center gap-x-1 font-semibold text-primary-blue"
              onClick={() => {
                trackEvents(COINS_OPEN_TRANSACTION_LIST)
                setTransactionHistoryDrawer(true)
              }}
            >
              <Image
                alt="icon"
                src="/assets/icons/ic_transaction_history.svg"
                width={20}
                height={20}
                style={{
                  maxWidth: '100%',
                  height: 'auto',
                }}
              />
              Riwayat transaksi
            </button>
            <button
              type="button"
              className="flex items-center gap-x-1 font-semibold text-primary-blue"
              onClick={() => {
                trackEvents(COINS_OPEN_PRICELIST)
                setCoinsPriceListDrawer(true)
              }}
            >
              <Image
                alt="icon"
                src="/assets/icons/ic_price_list.svg"
                width={20}
                height={20}
                style={{
                  maxWidth: '100%',
                  height: 'auto',
                }}
              />
              Daftar harga
            </button>
          </div>
        </div>
      </div>
      <CoinsExpiredModal />
    </>
  )
}

export default CoinsBanner
