/* eslint-disable no-underscore-dangle */
import { post } from '@helpers/fetch-wrapper'
import classNames from 'classnames'
import Image from 'next/image'
import React from 'react'
import useSWRImmutable from 'swr/immutable'

import usePriceList from '@features/coins/hooks/usePriceList'
import { GetPriceListAPIResponse } from '@features/coins/types'

import Drawer from '@components/drawer'
import Paragraph from '@components/typography/paragraph'

import { useAppSelector } from '@hooks/redux'

import { INITIALIZE_URL } from '@constants/url'

import { useCoinsActions } from '@store/coins/slice'

import CoinsDrawerTitle from '../coins-drawer-title'

const PriceListTabs = () => {
  const priceListTab = useAppSelector(
    (state) => state.coins.priceListDrawer.tab
  )
  const coinsActions = useCoinsActions()
  const { priceListData, priceListIsLoading } = usePriceList()

  const { data: initializeData } = useSWRImmutable<{
    featureToggle: {
      canBoost: boolean
      canUseCoins: boolean
    }
  }>(INITIALIZE_URL, async (url: string) => {
    const response = await post(url)
    return response.data
  })

  if (priceListIsLoading) {
    return (
      <div className="flex items-center justify-center p-4">
        <Image
          className="animate-spin"
          alt="animate-logo"
          src="/assets/images/img_spinner_blue.png"
          width={40}
          height={40}
          style={{
            maxWidth: '100%',
            height: 'auto',
          }}
        />
      </div>
    )
  }

  if (!priceListData) return null

  return (
    <div className="flex items-center gap-[8px] flex-wrap">
      <button
        type="button"
        className={classNames(
          priceListTab === 'JobPost:Create'
            ? 'bg-secondary-blue'
            : 'hover:bg-secondary-blue',
          'p-[12px]',
          'rounded-[6px]',
          priceListTab === 'JobPost:Create' && 'font-semibold',
          priceListTab === 'JobPost:Create' && 'text-primary-blue'
        )}
        onClick={() => {
          coinsActions.setProductListDrawerTab('JobPost:Create')
        }}
      >
        Buat loker
      </button>
      <button
        type="button"
        className={classNames(
          priceListTab === 'Candidate:Search'
            ? 'bg-secondary-blue'
            : 'hover:bg-secondary-blue',
          'p-[12px]',
          'rounded-[6px]',
          priceListTab === 'Candidate:Search' && 'font-semibold',
          priceListTab === 'Candidate:Search' && 'text-primary-blue'
        )}
        onClick={() => {
          coinsActions.setProductListDrawerTab('Candidate:Search')
        }}
      >
        Cari Kandidat
      </button>
      <button
        type="button"
        className={classNames(
          priceListTab === 'Candidate:TalentPool'
            ? 'bg-secondary-blue'
            : 'hover:bg-secondary-blue',
          'p-[12px]',
          'rounded-[6px]',
          priceListTab === 'Candidate:TalentPool' && 'font-semibold',
          priceListTab === 'Candidate:TalentPool' && 'text-primary-blue'
        )}
        onClick={() => {
          coinsActions.setProductListDrawerTab('Candidate:TalentPool')
        }}
      >
        Talent Pool
      </button>
      {initializeData?.featureToggle.canBoost && (
        <button
          type="button"
          className={classNames(
            priceListTab === 'Candidate:Boost'
              ? 'bg-secondary-blue'
              : 'hover:bg-secondary-blue',
            'p-[12px]',
            'rounded-[6px]',
            priceListTab === 'Candidate:Boost' && 'font-semibold',
            priceListTab === 'Candidate:Boost' && 'text-primary-blue'
          )}
          onClick={() => {
            coinsActions.setProductListDrawerTab('Candidate:Boost')
          }}
        >
          Boost
        </button>
      )}
      <button
        type="button"
        className={classNames(
          priceListTab === 'Others'
            ? 'bg-secondary-blue'
            : 'hover:bg-secondary-blue',
          'p-[12px]',
          'rounded-[6px]',
          priceListTab === 'Others' && 'font-semibold',
          priceListTab === 'Others' && 'text-primary-blue'
        )}
        onClick={() => {
          coinsActions.setProductListDrawerTab('Others')
        }}
      >
        Lainnya
      </button>
    </div>
  )
}

const PriceList = ({
  products,
}: {
  products: Array<GetPriceListAPIResponse>
}) => {
  return (
    <div className="flex flex-col gap-y-4 p-4">
      {products.map((product) => (
        <div
          key={`${product.productCode}-${product.quantity}`}
          className="flex justify-between rounded-xl border-2 p-4"
        >
          <Paragraph weight="semibold">{product.productName}</Paragraph>
          <div className="items-ceter flex gap-x-2">
            <Image
              alt="icon"
              src="/assets/icons/ic_coins.svg"
              height={20}
              width={20}
              style={{
                maxWidth: '100%',
                height: 'auto',
              }}
            />
            <Paragraph weight="semibold">{product.price}</Paragraph>
          </div>
        </div>
      ))}
    </div>
  )
}

const PriceListTabContent = () => {
  const currentTab = useAppSelector((state) => state.coins.priceListDrawer.tab)

  const { priceListData } = usePriceList()

  const [
    jobPostProducts,
    candidateSearchProducts,
    candidateBoostProducts,
    candidateTalentPoolProducts,
    otherProducts,
  ] = React.useMemo(() => {
    const _jobPostProducts: GetPriceListAPIResponse[] = []
    const _candidateBoostProducts: GetPriceListAPIResponse[] = []
    const _candidateSearchProducts: GetPriceListAPIResponse[] = []
    const _candidateTalentPoolProducts: GetPriceListAPIResponse[] = []
    const _otherProducts: GetPriceListAPIResponse[] = []

    if (!priceListData)
      return [
        _jobPostProducts,
        _candidateSearchProducts,
        _candidateBoostProducts,
        _candidateTalentPoolProducts,
        _otherProducts,
      ]

    priceListData.forEach((val) => {
      if (!val.isVisible) {
        // do nothing
      } else if (val.productCode.startsWith('JobPost:Create')) {
        _jobPostProducts.push(val)
      } else if (val.productCode.startsWith('Candidate:Search')) {
        _candidateSearchProducts.push(val)
      } else if (val.productCode.startsWith('Candidate:Boost')) {
        _candidateBoostProducts.push(val)
      } else if (val.productCode.startsWith('Candidate:TalentPool')) {
        _candidateTalentPoolProducts.push(val)
      } else {
        _otherProducts.push(val)
      }
    })

    return [
      _jobPostProducts,
      _candidateSearchProducts,
      _candidateBoostProducts,
      _candidateTalentPoolProducts,
      _otherProducts,
    ]
  }, [priceListData])

  if (currentTab === 'JobPost:Create')
    return <PriceList products={jobPostProducts} />
  if (currentTab === 'Candidate:Search')
    return <PriceList products={candidateSearchProducts} />
  if (currentTab === 'Candidate:Boost')
    return <PriceList products={candidateBoostProducts} />
  if (currentTab === 'Candidate:TalentPool')
    return <PriceList products={candidateTalentPoolProducts} />
  if (currentTab === 'Others') return <PriceList products={otherProducts} />
}

const PriceListDrawer = () => {
  const openPriceListDrawer = useAppSelector(
    (state) => state.coins.priceListDrawer.open
  )
  const { setCoinsPriceListDrawer } = useCoinsActions()

  const { data: initializeData } = useSWRImmutable<{
    featureToggle: {
      canBoost: boolean
      canUseCoins: boolean
    }
  }>(INITIALIZE_URL, async (url: string) => {
    try {
      const response = await post(url)
      return response.data
    } catch (e) {
      return undefined
    }
  })

  const onClose = () => {
    setCoinsPriceListDrawer(false)
  }

  if (!initializeData?.featureToggle.canUseCoins) return null

  return (
    <Drawer
      title={
        <CoinsDrawerTitle
          title="Daftar harga"
          onClose={() => setCoinsPriceListDrawer(false)}
        />
      }
      isOpen={openPriceListDrawer}
      onClose={onClose}
      hideCloseButton
    >
      <PriceListTabs />
      <PriceListTabContent />
    </Drawer>
  )
}

export default PriceListDrawer
