/* eslint-disable arrow-body-style */
import Image from 'next/image'
import React from 'react'
import NumberFormat from 'react-number-format'
import { toast } from 'react-toastify'

import useTransactionDetail from '@features/coins/hooks/useTransactionDetail'

import Paragraph from '@components/typography/paragraph'

import { useAppSelector } from '@hooks/redux'

const TransactionDetailInfo = () => {
  const { selectedTransactionId } = useAppSelector((state) => state.coins)

  const { transactionDetailData } = useTransactionDetail(selectedTransactionId)

  return (
    <div className="p-4">
      <div className="flex justify-between">
        <Paragraph accent="secondary" className="text-sm font-thin">
          ID transaksi
        </Paragraph>

        <div className="flex w-2/3 gap-x-2">
          <Paragraph className="text-end text-sm font-thin">
            {transactionDetailData?.referenceId}
          </Paragraph>
          <button
            type="button"
            onClick={() => {
              navigator.clipboard.writeText(
                transactionDetailData?.referenceId as string
              )
              toast('ID transaksi berhasil disalin.', {
                hideProgressBar: true,
                style: {
                  backgroundColor: '#52B788',
                  color: '#FFFFFF',
                },
              })
            }}
          >
            <Image
              alt="icon"
              src="/assets/icons/ic_copy.svg"
              width={20}
              height={20}
              style={{
                maxWidth: '100%',
                height: 'auto',
              }}
            />
          </button>
        </div>
      </div>

      {transactionDetailData?.transactionType === 'Credit' &&
        transactionDetailData?.paymentTransaction?.method && (
          <>
            <div className="mt-4 flex items-center justify-between">
              <Paragraph accent="secondary" className="text-sm font-thin">
                Metode pembayaran
              </Paragraph>
              <Paragraph className="text-sm font-thin">
                {transactionDetailData?.paymentTransaction?.method || '-'}
              </Paragraph>
            </div>

            <div className="mt-4 flex items-center justify-between">
              <Paragraph accent="secondary" className="text-sm font-thin">
                Total yang dibayar
              </Paragraph>
              <NumberFormat
                fixedDecimalScale={false}
                prefix="Rp. "
                decimalSeparator=","
                thousandSeparator="."
                decimalScale={0}
                value={transactionDetailData?.paymentTransaction?.realAmount}
                displayType="text"
              />
            </div>
          </>
        )}

      {transactionDetailData?.status === 'Failed' && (
        <div className="mt-4 flex items-start gap-x-2 rounded-lg bg-[#F4F6FB] p-2">
          <Image
            alt="icon"
            src="/assets/icons/ic_price_list.svg"
            width={30}
            height={30}
            style={{
              maxWidth: '100%',
              height: 'auto',
            }}
          />
          <Paragraph className="text">
            Transaksi gagal, saldo tidak bertambah.
          </Paragraph>
        </div>
      )}
    </div>
  )
}

export default TransactionDetailInfo
