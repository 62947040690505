import Image from 'next/image'
import React from 'react'

import Paragraph from '@components/typography/paragraph'

const CoinsEmptyState = () => (
  <div className="flex flex-col items-center justify-center gap-y-4">
    <Image
      alt="img"
      src="/assets/images/img_no_jp.svg"
      width={250}
      height={250}
      style={{
        maxWidth: '100%',
        height: 'auto',
      }}
    />
    <div className="flex flex-col items-center justify-center gap-y-1">
      <Paragraph weight="semibold" className="text-2xl">
        Belum ada transaksi
      </Paragraph>
      <Paragraph accent="secondary" className="font-thin">
        Semua transaksi akan tercatat di halaman ini
      </Paragraph>
    </div>
  </div>
)

export default CoinsEmptyState
